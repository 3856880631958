import { defineStore } from 'pinia';

export const useLoadingDialogStore = defineStore('loadingDialog', {
  state: () => ({
    active: false,
  }),
  actions: {
    openDialog() {
      this.active = true;
    },
    closeDialog() {
      this.active = false;
    },
  },
});