import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { i18n } from '@/plugins/i18n';
import { createVuestic } from 'vuestic-ui';

import Breadcrumb from '@/components/Breadcrumb.vue';
import BaseCheckboxGroup from '@/components/BaseCheckboxGroup.vue';
import BaseFileUploader from '@/components/BaseFileUploader.vue';
import LoadingDialog from '@/components/LoadingDialog.vue';

import App from './App.vue';
import router from './router';
import '@/styles/app.scss';
import 'vuestic-ui/dist/vuestic-ui.css';

import { price } from '@/utils/filters';
import { clickOutside } from '@/utils/directives';

const app = createApp(App);
app.component('BaseBreadcrumb', Breadcrumb);
app.component('BaseCheckboxGroup', BaseCheckboxGroup);
app.component('BaseFileUploader', BaseFileUploader);
app.component('LoadingDialog', LoadingDialog);

app.provide('$filters', {
  price,
});

//directive clickoutside
app.directive('click-outside', clickOutside);
app.use(createPinia());
app.use(i18n);
app.use(router);
app.use(createVuestic({
  config: {
    colors: {
      primary: '#0079AF',
      secondary: '#4890b1',
      tertiary: '#e5f1f4',
    },
  },
}));
app.mount('#app');

// Handling Global components TS types for intellisense
import { defineCustomElement } from '@vue/runtime-dom';

customElements.define('base-checkbox-group', defineCustomElement(BaseCheckboxGroup));
customElements.define('base-file-uploader', defineCustomElement(BaseFileUploader));

declare module '@vue/runtime-core' {
	export interface GlobalComponents {
    'base-checkbox-group': typeof BaseCheckboxGroup,
    'base-file-uploader': typeof BaseFileUploader,
	}
}