import { defineStore } from 'pinia';

export const useDialogStore = defineStore('dialog', {
  state: () => ({
    active: false,
    errorMessage: '',
  }),
  actions: {
    openDialog() {
      this.active = true;
    },
    closeDialog() {
      this.active = false;
    },
    setErrorMessage(errorMessage: string) {
      this.errorMessage = errorMessage;
    },
  },
});