import { createRouter, createWebHistory, RouteRecordRaw, RouterView } from 'vue-router';
import { h } from 'vue';
import { getCookie } from 'typescript-cookie';
import { SUPPORTED_LANGUAGES } from '@/constants/translation';
import { useTranslation } from '@/composables/useTranslation';

// This will be used for checking the language prefix in the URL
const { routeMiddleware } = useTranslation();

const routes: Array<RouteRecordRaw> = [
  // if there's no lang prefix in the URL then force to use the first supported lang
  {
    path: '/',
    redirect: {
      name: 'login',
      params: {
        lang: SUPPORTED_LANGUAGES[0], // de
      },
    },
  },
  {
    // Redirect user to supported lang version.
    path: '/:lang',
    component: { render: () => h(RouterView) },
    beforeEnter: routeMiddleware,
    children:[
      {
        path: '',
        redirect: {
          name: 'banners',
        },
      },
      {
        path: 'login',
        name: 'login',
        component: () => import('@/views/login/index.vue'),
        meta: {
          layout: 'LoginLayout',
        },
      },
      {
        path: 'banners',
        name: 'banners',
        component: () => import('@/views/banner/index.vue'),
        meta: {
          layout: 'MainLayout',
          navigationMenu: 'banners',
        },
      },
      {
        path: 'banners/create',
        name: 'banners_create',
        component: () => import('@/views/banner/create/index.vue'),
        meta: {
          layout: 'MainLayout',
          navigationMenu: 'banners',
        },
      },
      {
        path: 'banners/:bannerId',
        name: 'banners_bannerId',
        component: () => import('@/views/banner/_bannerId/index.vue'),
        meta: {
          layout: 'MainLayout',
          navigationMenu: 'banners',
        },
      },
      {
        path: 'admin/users',
        name: 'admin_users',
        component: () => import('@/views/admin/user/index.vue'),
        meta: {
          layout: 'MainLayout',
          navigationMenu: 'admin_users',
        },
      },
      {
        path: 'admin/users/create',
        name: 'admin_users_create',
        component: () => import('@/views/admin/user/create/index.vue'),
        meta: {
          layout: 'MainLayout',
          navigationMenu: 'admin_users',
        },
      },
      {
        path: 'admin/customers',
        name: 'admin_customers',
        component: () => import('@/views/admin/customer/index.vue'),
        meta: {
          layout: 'MainLayout',
          navigationMenu: 'admin_customers',
        },
      },
      {
        path: 'admin/customers/create',
        name: 'admin_customers_create',
        component: () => import('@/views/admin/customer/create/index.vue'),
        meta: {
          layout: 'MainLayout',
          navigationMenu: 'admin_customers',
        },
      },
      {
        path: 'activity-logs',
        name: 'activity-logs',
        component: () => import('@/views/activity-log/index.vue'),
        meta: {
          layout: 'MainLayout',
          navigationMenu: 'activity-logs',
        },
      },
      // {
      //   path: 'account-info',
      //   name: 'account-info',
      //   component: () => import('@/views/account-info/index.vue'),
      //   meta: {
      //     layout: 'MainLayout',
      //   },
      // },
      {
        path: ':pathMatch(.*)*',
        name: 'not-found',
        component: () => import('@/views/notFound.vue'),
        meta: {
          layout: 'MainLayout',
        },
      },
    ],
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const accessToken = getCookie('user_access_token');
  const lang = to.params.lang;
  if (to.name === 'login') {
    if (accessToken) {
      next({ name: 'banners', params: { lang } });
    } else {
      next();
    }
  } else {
    if (accessToken) {
      next();
    } else {
      next({ name: 'login', params: { lang } });
    }
  }
});

export default router;
