import { getCookie, removeCookie } from 'typescript-cookie';
import axios from 'axios';
import { i18n } from '@/plugins/i18n';
import { useLoadingDialogStore } from '@/stores/useLoadingDialogStore';

// export const BASE_API_URL = process.env.NODE_ENV === 'development' || window.location.host.includes('dev')
export const BASE_API_URL = process.env.NODE_ENV === 'development'
  ? 'http://localhost:8000/v1'
  // : 'https://dev-frontend-ad-server.hosting.jfnet.de/v1';
  : '/v1';

export const apiClient = axios.create({
  baseURL: BASE_API_URL,
});

apiClient.interceptors.request.use((config: any) => {
  const userAccessToken = getCookie('user_access_token');
  if (userAccessToken) {
    config.headers.Authorization = userAccessToken;
  }
  return config;
});

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (!error.response) {
      return Promise.reject(error);
    }

    if (error.response.status === 401) {
      removeCookie('user_access_token');
      removeCookie('user_role');
      const loadingDialogStore = useLoadingDialogStore();
      loadingDialogStore.closeDialog();
      const { locale } = i18n.global;
      window.location.href = `/${locale.value.split('-')[0]}/login`;
    }

    return Promise.reject(error);
  },
);

export const apiBlob = axios.create({
  baseURL: BASE_API_URL,
  responseType: 'blob',
});

apiBlob.interceptors.request.use((config: any) => {
  const userAccessToken = getCookie('user_access_token');
  if (userAccessToken) {
    config.headers.Authorization = userAccessToken;
  }

  return config;
});

apiBlob.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (!error.response) {
      return Promise.reject(error);
    }
    if (error.response.status === 401) {
      removeCookie('user_access_token');
      removeCookie('user_role');
      const loadingDialogStore = useLoadingDialogStore();
      loadingDialogStore.closeDialog();
      const { locale } = i18n.global;
      window.location.href = `/${locale.value.split('-')[0]}/login`;
    }

    return Promise.reject(error);
  },
);
